import { type ThunkAction, configureStore, type ThunkDispatch } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import authReducer from "./authSlice";
import memberReducer from "./memberSlice";
import userReducer from "./userSlice";
import loanReducer from "./loanSlice";
import tillReducer from "./tillSlice";
import tellerReducer from "./tellerSlice";
import savingReducer from "./savingSlice";
import { useDispatch } from "react-redux";
import { type UnknownAsyncThunkAction } from "@reduxjs/toolkit/dist/matchers";

export const store = configureStore({
  reducer: {
    app: appSlice,
    auth: authReducer,
    member: memberReducer,
    user: userReducer,
    savings: savingReducer, // Add your savingsReducer here
    loan: loanReducer,
    till: tillReducer,
    teller: tellerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        // ignoredActions: ["till/setNewTillTransaction"],
        // Ignore these paths in the state
        ignoredPaths: [
          "member.memberInfo.memberStatus.dateOfBirth",
          "member.memberInfo.newMember.profileImage",
          "member.memberInfo.memberDocuments.memberShipApplication.file",
          "member.memberInfo.memberDocuments.kraPinCertificate.file",
          "member.memberInfo.memberDocuments.signature.file",
          "member.memberInfo.memberDocuments.salarySlip.file",
          "member.memberInfo.memberDocuments.passportPhoto.file",
          "member.memberInfo.memberDocuments.frontId.file",
          "member.memberInfo.memberDocuments.backId.file",
        ],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): ThunkDispatch<RootState, any, any> => useDispatch<AppDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAsyncThunkAction>;
