import { useEffect, useState } from "react";
import {
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  useTheme,
  Menu,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../redux/store";
import { fetchOffices, setOffice } from "../../redux/appSlice";
import { useSelector } from "react-redux";
import { IRoute } from "../types";

interface SideNavProps {
  open: boolean;
  onToggleNav: () => any;
  drawerWidth: number;
  isMobile: boolean;
  routes: IRoute[];
}

function SideNav({ open, onToggleNav, drawerWidth, isMobile, routes }: SideNavProps): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch()
  const { offices, office } = useSelector((state: RootState) => state.app)

  const currentLocation = location.pathname;

  const currentPathKey = routes.find((el) =>
    el.routes?.some((subEl) => currentLocation.includes(subEl.path ?? ""))
  )?.key;

  const [expanded, setExpanded] = useState([currentPathKey]);

  const toggleNav = (key: string): void => {
    if (!expanded.includes(key)) {
      setExpanded([...expanded, key]);
    } else {
      setExpanded(expanded.filter((el) => el !== key));
    }
  };

  const onClickMenu = ({ key, path, isExternal }: IRoute): void => {
    if (path != null) {
      if (isMobile) {
        onToggleNav();
      }
      if (isExternal === true) {
        window.open(path, "_blank");
      } else {
        navigate(path);
      }
    } else {
      toggleNav(key ?? "");
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openx = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if ((offices?.length ?? 0) === 0) {
      dispatch(fetchOffices())
    }
  }, [dispatch, offices?.length])

  return (
    <Drawer
      variant={isMobile ? "temporary" : "persistent"}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box", border: "none" },
      }}
      onClose={onToggleNav}
      open={open}
    >
      <Toolbar />
      <List component="nav" aria-labelledby="nested-list-subheader" sx={{ p: 2 }}>
        <OutlinedInput
          value={office?.name}
          size="small"
          color="primary"
          onClick={(e) => handleClick(e)}
          endAdornment={openx ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        />
        <Menu
          anchorEl={anchorEl}
          open={openx}
          defaultValue={office?.id}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {offices?.map((office, index) => <MenuItem key={index} value={office.id} onClick={(event) => {
            setAnchorEl(event.currentTarget);
            handleClose()
            dispatch(setOffice(office!))
          }}>{office.name}</MenuItem>)}
        </Menu>
        {routes.filter(route => !route.hidden).map((route) => (
          <div key={route.key}>
            <ListItemButton
              onClick={() => {
                onClickMenu(route);
              }}
              href={""}
              target={route.isDownload === true ? "_blank" : "_self"}
              selected={currentLocation === route.path}
              sx={{
                my: 1,
                p: 2,
                borderRadius: 2,
                "&.Mui-selected": { color: theme.palette.primary.main },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 1, color: "inherit" }}>
                <img src={`/web_assets/${route.icon}`} alt="" width={30} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="h6">{route.label}</Typography>} />
              {route.routes != null && expanded.includes(route.key) && <ExpandLess />}
              {route.routes != null && !expanded.includes(route.key) && <ExpandMore />}
            </ListItemButton>
            {route.routes != null && (
              <Collapse in={expanded.includes(route.key)}>
                {route.routes.filter(route => !route.hidden).map((subRoute) => {
                  return (
                    <List
                      key={subRoute.path}
                      component="div"
                      onClick={() => {
                        onClickMenu(subRoute);
                      }}
                      disablePadding
                      dense
                    >
                      <ListItemButton
                        sx={{
                          pl: 8,
                          "&.Mui-selected": {
                            color: theme.palette.primary.main,
                          },
                        }}
                        selected={currentLocation === subRoute.path}
                      >
                        <ListItemText primary={subRoute.label} />
                      </ListItemButton>
                    </List>
                  );
                })}
              </Collapse>
            )}
          </div>
        ))}
      </List>
      <Typography sx={{ mt: "auto", textAlign: "center", mb: 1 }} variant="body2">
        v {process.env.REACT_APP_VERSION}
      </Typography>
    </Drawer>
  );
}

SideNav.defaultProps = {
  isMobile: false,
};

export default SideNav;
