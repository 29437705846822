import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../redux/store";
import { IRoute } from "./types";

export default function useDashboardRoutes() {
  const { user } = useSelector((state: RootState) => state.auth)

  const location = useLocation();

  // Dashboard routes - Filter based on role
  const routes: IRoute[] = [
    {
      key: "add-new",
      label: "Add New",
      path: "/dashboard",
      icon: "Add New@2x.png",
      roles: []
    }, {
      key: "add-new",
      label: "Welcome",
      path: "/dashboard/cashier",
      icon: "Add New@2x.png",
      roles: ['Cashier Role']
    },
    {
      key: "dashboard",
      label: "Dashboard",
      path: "/analytics",
      icon: "Dashboard@2x.png",
      roles: ['Super user']
    },
    {
      key: "members",
      label: "Members",
      path: "/members",
      icon: "Members@2x.png",
      roles: ['Super user', 'Cashier Role']
    },
    {
      key: "loans",
      label: "Loans",
      path: "/loans",
      icon: "myloans@2x.png",
      roles: ['Super user', 'Cashier Role']
    },
    {
      key: "savings",
      label: "Savings",
      path: "/savings",
      icon: "mysavings@2x.png",
      roles: ['Super user', 'Cashier Role']
    },
    {
      key: "tills",
      label: "Tills",
      path: "/tills",
      icon: "Tills@2x.png",
      roles: ['Super user']
    },
    {
      key: "tellers",
      label: "Tellers",
      path: "/tellers",
      icon: "Teller@2x.png",
      roles: ['Super user']
    },
    {
      key: "staff",
      label: "Staff",
      path: "/staff",
      icon: "Members@2x.png",
      roles: ['Super user']
    },
    // Other Member routes hidden
    {
      hidden: true,
      path: "/members/add",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/edit",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/close",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/list",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*/savings/*",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*/savings/*/journals",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*/savings/*/journals/*",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/members/*/loans/*",
      roles: ['Super user', 'Cashier Role']
    },
    // Other teller routes
    {
      hidden: true,
      path: "/tellers/*",
      roles: ['Super user']
    },
    {
      hidden: true,
      path: "/tellers/deposit",
      roles: ['Super user', 'Cashier Role']
    },
    {
      hidden: true,
      path: "/tellers/withdraw",
      roles: ['Super ', 'Cashier Role']
    },
    // Other Loans routes
    {
      hidden: true,
      path: "/loans/add",
      roles: ['Super user']
    },
    // {
    //   key: "reports",
    //   label: "Reports",
    //   path: "/reports",
    //   icon: "Reports@2x.png",
    // },
    // {
    //   key: "finance",
    //   label: "Finance",
    //   path: "/finance",
    //   icon: "Finance@2x.png",
    // },
    // {
    //   key: "settings",
    //   label: "Settings",
    //   path: "/settings",
    //   icon: "Settings@2x.png",
    // },
    // {
    //   key: "more",
    //   label: "More",
    //   path: "/more",
    //   icon: "More@2x.png",
    // },
  ].filter(role => {
    if (role.roles?.length === 0) {
      return true
    }
    for (const urole of (user?.roles ?? [])) {
      if (role.roles?.includes(urole.name)) {
        return true;
      }
    }
    return false;
  });

  const allowedRoute = routes.some(route => {
    if (route.path === location.pathname) {
      return true
    }
    if (route.path?.includes('*')) {
      const regex = new RegExp('^' + route.path.replace(/\*/g, '.*') + '$');
      return regex.test(location.pathname);
    }
    return false;
  })

  return { routes, allowedRoute }
}