import { type PropsWithChildren } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import getThemeByName from ".";
import { getThemeName } from "../utils/helpers";

function MyThemeProvider({ children }: PropsWithChildren): JSX.Element {
  const themeName = getThemeName();
  const theme = getThemeByName(themeName);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default MyThemeProvider;
