import { get, post } from "./base";

const tkbApi = {
  auth: {
    login: (data: any) => post("/clients", data),
  },
  loan: {
    fetch: () => get("/loans"),
    fetchLoanProducts: () => get("/loanproducts"),
    // fetchClientLoanTemplate: (clientId: string | number) => get(`/loans/template?template=individual&clientId=${clientId}`),
    submitApplication: (data: any) => post("/self/loans", data),
  },
  teller: {
    fetch: () => get("/tellers"),
    fetchLoanTxnTemplate: (id: string | number) => get(`/loans/${id}/transactions/template?command=repayment`),
    makeLoanRepayment: (id: string | number, data: any) => post(`/loans/${id}/transactions?command=repayment`, data),
    makeSavingsDeposit: (id: string | number, data: any) =>
      post(`/savingsaccounts/${id}/transactions?command=deposit`, data),
    makeSavingsWithdrawal: (id: string | number, data: any) =>
      post(`/savingsaccounts/${id}/transactions?command=withdrawal`, data),
  },
  client: {
    fetchImage: (id: string | number) => get(`/clients/${id}/images`),
    fetchSavingsAccounts: (id: string | number) => get(`/clients/${id}/accounts?fields=savingsAccounts`),
    fetchLoanAccounts: (id: string | number) => get(`/clients/${id}/accounts?fields=loanAccounts`),
  },
};

export default tkbApi;
