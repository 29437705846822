import defaultTheme from "./defaultTheme";
import defaultDarkTheme from "./defaultDarkTheme";
import { type Theme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      foo: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      foo?: string;
    };
  }
}

const themeMap: Record<string, Theme> = {
  default: defaultTheme,
  defaultDark: defaultDarkTheme,
};

function getThemeByName(name: string): Theme {
  return themeMap[name];
}

export default getThemeByName;
