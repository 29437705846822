import { useSelector } from 'react-redux'
import { updateSucess } from '../redux/appSlice'
import { useAppDispatch, RootState } from '../redux/store'
import SuccesComponent from '../components/shared/SuccessModal'

export default function SuccessProvider({ children }: any) {
  const dispatch = useAppDispatch()
  const { success } = useSelector((state: RootState) => state.app)

  return <>
    <SuccesComponent
      open={success?.successActive ?? false}
      closeDialog={() => {
        dispatch(updateSucess({ successActive: false, successMessage: '' }))
      }}
    />
    {children}
  </>
}
