import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const PRIMARY_COLOR = "#1c355e";
const SECONDARY_COLOR = grey[600];

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    background: {
      default: "#f1f8ff",
    },
    success: {
      main: "#98d9cc",
    },
    warning: {
      main: "#ffe1bf",
    },
  },
  typography: {
    fontFamily: ["Rubik"].join(","),
    h3: {
      color: PRIMARY_COLOR,
      fontWeight: 500,
    },
    h5: {
      color: PRIMARY_COLOR,
      fontWeight: 500,
    },
    h6: {
      color: PRIMARY_COLOR,
      fontWeight: 500,
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
      color: SECONDARY_COLOR,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "unset",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
  },
});

export default defaultTheme;
