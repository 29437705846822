import { useState, useEffect, lazy, type LazyExoticComponent, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingScreen from "./components/LoadingScreen";
import DashboardLayout from "./layout/DashboardLayout";
import FullPageLayout from "./layout/FullPageLayout";
import { useAppDispatch, type RootState } from "./redux/store";
import { logout } from "./redux/authSlice";

function Loadable(Component: LazyExoticComponent<any>): JSX.Element {
	return (
		<Suspense fallback={<LoadingScreen />}>
			<Component />
		</Suspense>
	);
}

const Login = Loadable(lazy(async () => await import("./pages/Auth/Login").then((module) => ({ default: module.default }))));
const MainDashboard = Loadable(lazy(async () => await import("./pages/Dashboard/MainDashboard").then((module) => ({ default: module.default }))));
const CashierDashboard = Loadable(lazy(async () => await import("./pages/Dashboard/CashierDashboard").then((module) => ({ default: module.default }))));

// Member pages
const MembersLanding = Loadable(lazy(async () => await import("./pages/Members/pages/landing").then((module) => ({ default: module.default }))));
const MembersPage = Loadable(lazy(async () => await import("./pages/Members/pages/members").then((module) => ({ default: module.default }))));
const ViewMember = Loadable(lazy(async () => await import("./pages/Members/pages/viewMember").then((module) => ({ default: module.default }))));
const EditMember = Loadable(lazy(async () => await import("./pages/Members/pages/editMember").then((module) => ({ default: module.default }))));
const MemberSaving = Loadable(lazy(async () => await import("./pages/Members/pages/memberSaving").then((module) => ({ default: module.default }))));
const MemberSavingJournalEntries = Loadable(lazy(async () => await import("./pages/Members/pages/savingJournalEntries").then((module) => ({ default: module.default }))));
const MemberSavingJournalTransaction = Loadable(lazy(async () => await import("./pages/Members/pages/savingJournalTransactions").then((module) => ({ default: module.default }))));
const CloseMember = Loadable(lazy(async () => await import("./pages/Members/pages/closeMember").then((module) => ({ default: module.default }))));
const MemberLoan = Loadable(lazy(async () => await import("./pages/Members/pages/memberLoan").then((module) => ({ default: module.default }))));

// Loans pages
const ListLoans = Loadable(lazy(async () => await import("./pages/Loan/ListLoans").then((module) => ({ default: module.default }))));
const AddLoanPage = Loadable(lazy(async () => await import("./pages/Loan/AddLoan").then((module) => ({ default: module.default }))));

// Savings pagess
const SavingsPage = Loadable(lazy(async () => await import("./pages/Savings/Savings").then((module) => ({ default: module.default }))));
const AddMember = Loadable(lazy(async () => await import("./pages/Members/pages/addMember").then((module) => ({ default: module.default }))));
const AddSavings = Loadable(lazy(async () => await import("./pages/Savings/AddSavings").then((module) => ({ default: module.default }))));

// Tills and tellers
const ListTills = Loadable(lazy(async () => await import("./pages/Tills/ListTills").then((module) => ({ default: module.default }))));
const ListTellers = Loadable(lazy(async () => await import("./pages/Tellers/pages/tellers").then((module) => ({ default: module.default }))));
const TellerInfo = Loadable(lazy(async () => await import("./pages/Tellers/pages/tellerInfo").then((module) => ({ default: module.default }))));
const CashierInfo = Loadable(lazy(async () => await import("./pages/Tellers/pages/cashierInfo").then((module) => ({ default: module.default }))));
const DepositSaving = Loadable(lazy(async () => await import("./pages/Tellers/pages/depositSaving").then((module) => ({ default: module.default }))));
const WithdrawSaving = Loadable(lazy(async () => await import("./pages/Tellers/pages/withdrawSaving").then((module) => ({ default: module.default }))));
const AddTransaction = Loadable(lazy(async () => await import("./pages/Tills/AddTransaction").then((module) => ({ default: module.default }))));

// Staff and Users
const ListStaffs = Loadable(lazy(async () => await import("./pages/Staff/pages/staffs").then((module) => ({ default: module.default }))));

const Analytics = Loadable(
	lazy(async () => await import("./pages/Dashboard/Analytics").then((module) => ({ default: module.default })))
);

function Router(): JSX.Element {
	const dispatch = useAppDispatch();
	const [isInitialized, setInitialized] = useState(false);
	const { user } = useSelector((state: RootState) => state.auth);

	window.logout = () => {
		dispatch(logout());
	};

	useEffect(() => {
		if (!isInitialized) {
			setInitialized(true);
		}
	}, [isInitialized]);

	return (
		<BrowserRouter>
			<Routes>
				{!isInitialized && <Route path="*" element={<LoadingScreen />} />}
				{user == null ? (
					<>
						<Route path="/" element={user == null ? <FullPageLayout /> : <Navigate to="/dashboard" replace />}>
							<Route path="" element={Login} />
						</Route>
						<Route path="*" element={<Navigate to="/" />} />
					</>
				) : (
					<>
						<Route path="members" element={<DashboardLayout />}>
							<Route path="" element={MembersLanding} />
							<Route path="add" element={AddMember} />
							<Route path="edit" element={EditMember} />
							<Route path="close" element={CloseMember} />
							<Route path="list" element={MembersPage} />
							<Route path=":memberId" element={ViewMember} />
							<Route path=":memberId/savings/:savingId" element={MemberSaving} />
							<Route path=":memberId/savings/:savingId/journals" element={MemberSavingJournalEntries} />
							<Route path=":memberId/savings/:savingId/journals/:transactionId" element={MemberSavingJournalTransaction} />
							<Route path=":memberId/loans/:loanId" element={MemberLoan} />
						</Route>
						<Route path="dashboard" element={user != null ? <DashboardLayout /> : <Navigate to="/" replace />}>
							<Route path="" element={MainDashboard} />
							<Route path="cashier" element={CashierDashboard} />
						</Route>
						<Route path="loans" element={user != null ? <DashboardLayout /> : <Navigate to="/" replace />}>
							<Route path="" element={ListLoans} />
							<Route path="add" element={AddLoanPage} />
						</Route>
						<Route path="analytics" element={user != null ? <DashboardLayout /> : <Navigate to="/" replace />}>
							<Route path="" element={Analytics} />
						</Route>
						<Route path="savings" element={user != null ? <DashboardLayout /> : <Navigate to="/" replace />}>
							<Route path="" element={SavingsPage} />
						</Route>
						<Route path="savings/add" element={user != null ? <DashboardLayout /> : <Navigate to="/" replace />}>
							<Route path="" element={AddSavings} />
						</Route>
						<Route path="tills" element={user != null ? <DashboardLayout /> : <Navigate to="/" replace />}>
							<Route path="" element={ListTills} />
							<Route path="add-transaction" element={AddTransaction} />
						</Route>
						<Route path="tellers" element={user != null ? <DashboardLayout /> : <Navigate to="/" replace />}>
							<Route path="" element={ListTellers} />
							<Route path=":tellerId" element={TellerInfo} />
							<Route path=":tellerId/cashiers/:cashierId" element={CashierInfo} />
							<Route path="deposit" element={DepositSaving} />
							<Route path="withdraw" element={WithdrawSaving} />
						</Route>
						<Route path="staff" element={user != null ? <DashboardLayout /> : <Navigate to="/" replace />}>
							<Route path="" element={ListStaffs} />
						</Route>
						<Route path="*" element={<Navigate to="/dashboard" />} />
					</>
				)}
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
