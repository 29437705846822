import secureLocalStorage from "react-secure-storage";

export function setUserDetails(user: any) {
  secureLocalStorage.setItem("user", JSON.stringify(user));
}

export function getUserDetails() {
  try {
    const user = JSON.parse((secureLocalStorage.getItem("user") as string) ?? "null");
    return user;
  } catch (error) {
    return null;
  }
}

export function setToken(token: string) {
  secureLocalStorage.setItem("token", JSON.stringify(token));
}

export function getToken() {
  return (secureLocalStorage.getItem("token") as string);
}

export function setThemeName(themeName: string) {
  secureLocalStorage.setItem("theme", themeName);
}

export function getThemeName() {
  return (secureLocalStorage.getItem("theme") as string) ?? "default";
}

export function getAPIHeaders(): Headers {
  const headers = new Headers();
  headers.append("Authorization", `Basic ${getToken()}`.replaceAll("\"", ""));
  headers.append("fineract-platform-tenantid", "default");
  return headers;
}

export function bytesToKilobytes(bytes: any) {
  return (bytes / 1024).toFixed(2);
}

function jaccardSimilarity(arr1: string[], arr2: string[]): number {
  let set1 = new Set(arr1);
  let set2 = new Set(arr2);
  let intersection = new Set([...set1].filter(x => set2.has(x)));
  let union = new Set([...set1, ...set2]);
  return intersection.size / union.size;
}

function calculateNameSimilarity(name1: string, name2: string): number {
  // Convert names to lowercase and split into arrays of words
  let nameArray1 = name1.toLowerCase().split(' ').sort().map(name => name.trim());
  let nameArray2 = name2.toLowerCase().split(' ').sort().map(name => name.trim());

  // Calculate Jaccard similarity
  let similarity = jaccardSimilarity(nameArray1, nameArray2);

  // Convert similarity to percentage
  let similarityPercentage = similarity * 100;

  return similarityPercentage;
}

export function areNamesSimilar(name1: string, name2: string): boolean {
  let similarityPercentage = calculateNameSimilarity(name1, name2);
  return similarityPercentage > 90;
}

export function generateTransactionReceipt() {
  // Use the current time as a seed
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  // Generate a 10-character random string
  let randomString = '';
  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  // Format the transaction receipt number
  const receiptNumber = `KBS${randomString}`;

  return receiptNumber;
}
