import { Snackbar } from '@mui/material'
import { useSelector } from 'react-redux'
import { updateSnackbar } from '../redux/appSlice'
import { useAppDispatch, RootState } from '../redux/store'

export default function SnackbarProvider({ children }: any) {
  const dispatch = useAppDispatch()
  const { snackbar } = useSelector((state: RootState) => state.app)

  return <>
    <Snackbar
      open={snackbar?.snackbarActive}
      autoHideDuration={6000}
      onClose={() => { dispatch(updateSnackbar({ snackbarActive: false, snackbarMessage: '' })) }}
      anchorOrigin={snackbar?.snackbarPosition ?? { vertical: 'bottom', horizontal: 'center' }}
      message={snackbar?.snackbarMessage}
      action={'Close'}
    />
    {children}
  </>
}
