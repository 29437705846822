import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogContent, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessComponent({ open, closeDialog, children }: { open: boolean, closeDialog?: Function, children?: any }) {

  function handleClose() {
    if (!!closeDialog) {
      closeDialog()
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minWidth: '350px', maxWidth: '450px', minHeight: '350px', maxHeight: '450px' }}>
          <Stack spacing={3} p={5} justifyContent='center' alignItems='center'>
            {!children ?? <Typography color='success' typography='title'>Success</Typography>}
            <img src='/web_assets/success.png' alt='success' height={150} width={150} />
            {children}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}