import { Box, CircularProgress } from "@mui/material";

interface LoadingScreenProps {
  height?: number;
}

function LoadingScreen({ height = 100 }: LoadingScreenProps): JSX.Element {
  return (
    <Box sx={{ display: "flex", minHeight: `${height}vh` }}>
      <CircularProgress sx={{ margin: "auto" }} />
    </Box>
  );
}

export default LoadingScreen;
