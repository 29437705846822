export async function handleResponse(response: Response) {
	try {
		let data;
		const ctype = response.headers.get('content-type')
		switch (ctype) {
			case 'application/json':
				data = await response.json();
				break;
			case 'application/json; charset=utf-8':
				data = await response.json();
				break;
			case 'application/text':
				data = await response.text();
				break;
			case 'text/plain; charset=utf-8':
				data = await response.text();
				break;
			case 'text/plain':
				data = await response.text();
				break;
			case 'application/pdf':
				data = await response.blob();
				break;
			case 'text/csv':
				data = await response.blob();
				break;
			case 'application/octet-stream':
				data = await response.blob();
				break;
			default:
				if (ctype?.startsWith('image/') || ctype?.startsWith('application/')) {
					data = await response.blob();
				} else {
					data = await response.text();
				}
		}

		if (!response.ok) {
			return Promise.reject(data);
		}

		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject('invalid response was received');
	}
}

export function handleAPIError(setter: (err: string) => void, error: any) {
	if (typeof error === 'string') {
		setter(error.substring(0, 100))
	} else if (error.errors) {
		setter(error.errors[0].defaultUserMessage)
	} else if (error.defaultUserMessage) {
		setter(error.defaultUserMessage)
	} else if (error.error) {
		setter(error.error)
	} else {
		setter('network error')
	}
}

export function cleanApiError(error: any) {
	if (error.errors != null && error.errors.length > 0) {
		return error.errors[0].defaultUserMessage
	} else if (error.defaultUserMessage) {
		return error.defaultUserMessage
	} else if (error.error) {
		return error.error
	} else if (typeof error === 'string') {
		return error.substring(0, 100)
	} else {
		return ('network error')
	}
}