import { useState } from "react";
import { Box, IconButton, Menu, MenuItem, OutlinedInput } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { RootState } from "../../redux/store";

export default function UserMenu() {
  const { user } = useSelector((state: RootState) => state.auth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton color="primary" onClick={handleClick} sx={{ display: { md: "none" } }}>
        <AccountCircleIcon />
      </IconButton>
      <OutlinedInput
        size="small"
        value={user?.username}
        color="primary"
        onClick={(e) => handleClick(e)}
        sx={{ display: { xs: "none", md: "flex" }, width: "12rem", borderRadius: "1rem", cursor: "pointer" }}
        inputProps={{
          style: {
            padding: 0,
            paddingLeft: 12,
            paddingRight: 12,
            outline: "none",
            borderRadius: "1rem",
            cursor: "pointer",
          },
        }}
        endAdornment={open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>Settings</MenuItem>
        <MenuItem onClick={window.logout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
}
