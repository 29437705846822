import AppConfig from "../../../lib/AppConfig";
import { cleanApiError, handleResponse } from "../../../utils/handleResponse";
import { getAPIHeaders } from "../../../utils/helpers";
import { Office } from "../types/response/Office";

export namespace OfficeRepository {
  // listOffices
  export async function listOffices(): Promise<Office[]> {
    try {
      let url = `${AppConfig.api_url}/fineract-provider/api/v1/offices`;

      let response = await fetch(url, {
        headers: getAPIHeaders()
      });

      const data = await handleResponse(response)

      return data;
    } catch (error: any) {
      console.log('Request failed');
      throw cleanApiError(error)
    }
  }
}