import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getUserDetails, setUserDetails, setToken, getToken } from "../utils/helpers";

export interface IAuthUser {
    username: string;
    officeId: number;
    userId: number;
    roles?: any[];
}

export interface AuthSlice {
    user: IAuthUser | null;
    authLoading: boolean;
    token: string | null;
    isAuthenticated: boolean;
    roles?: string[];
}

const initialState: AuthSlice = {
    user: getUserDetails() as IAuthUser,
    authLoading: true,
    token: getToken(),
    isAuthenticated: false,
    roles: [],
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<IAuthUser>) => {
            state.user = action.payload;
            setUserDetails(action.payload);
        },
        setAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.authLoading = action.payload;
        },
        logout: (state) => {
            state.user = null;
            setUserDetails(null);
        },
        login: (state, action: PayloadAction<any>) => {
            state.token = action.payload.base64EncodedAuthenticationKey;
            state.isAuthenticated = true;
            state.authLoading = false;
            const user = {
                username: action.payload.username,
                officeId: action.payload.officeId,
                userId: action.payload.userId,
                roles: action.payload.roles,
            };

            state.user = user;
            state.roles = action.payload.roles;

            setUserDetails(user);
            setToken(action.payload.base64EncodedAuthenticationKey);
        },
    },
});

export const { setUser, logout, setAuthLoading, login } = authSlice.actions;

export default authSlice.reducer;
