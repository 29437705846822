import { Outlet } from "react-router-dom";

function FullPageLayout(): JSX.Element {
  return (
    <main>
      <Outlet />
    </main>
  );
}

export default FullPageLayout;
