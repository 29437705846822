import { createSlice, Dispatch, type PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "./store";
import tkbApi from "../api/tkbApi";

export interface ITeller {
  id: number;
  officeId: number;
  debitAccountId: number;
  creditAccountId: number;
  name: string;
  description: string;
  startDate: number[];
  status: string;
  officeName: string;
}
export interface ITillTransaction {
  paymentTypeId: number;
  txn_type: string;
  txn_date: string;
  txn_amount: number;
  account_no: string;
  cheque_number: string;
  routing_code: string;
  receipt_no: string;
  bank_no: string;
  note: string;
}
export interface IClientLoanAccount {
  id: number;
  accountNo: string;
  externalId: string;
  productId: number;
  productName: string;
  shortProductName: string;
  status: Status;
  loanType: LoanType;
  loanCycle: number;
  timeline: Timeline;
  inArrears: boolean;
  originalLoan: number;
  loanBalance: number;
  amountPaid: number;
}
export interface LoanType {
  id: number;
  code: string;
  value: string;
}
export interface Status {
  id: number;
  code: string;
  value: string;
  pendingApproval: boolean;
  waitingForDisbursal: boolean;
  active: boolean;
  closedObligationsMet: boolean;
  closedWrittenOff: boolean;
  closedRescheduled: boolean;
  closed: boolean;
  overpaid: boolean;
}
export interface Timeline {
  submittedOnDate: number[];
  submittedByUsername: string;
  submittedByFirstname: string;
  submittedByLastname: string;
  approvedOnDate: number[];
  approvedByUsername: string;
  approvedByFirstname: string;
  approvedByLastname: string;
  expectedDisbursementDate: number[];
  actualDisbursementDate: number[];
  disbursedByUsername: string;
  disbursedByFirstname: string;
  disbursedByLastname: string;
  expectedMaturityDate: number[];
}
export interface IClientSavingsAccount {
  id: number;
  accountNo: string;
  externalId: string;
  productId: number;
  productName: string;
  shortProductName: string;
  status: Status;
  currency: Currency;
  accountBalance: number;
  accountType: TType;
  timeline: Timeline;
  subStatus: SubStatus;
  lastActiveTransactionDate: number[];
  depositType: TType;
}
export interface TType {
  id: number;
  code: string;
  value: string;
}
export interface Currency {
  code: string;
  name: string;
  decimalPlaces: number;
  inMultiplesOf: number;
  displaySymbol: string;
  nameCode: string;
  displayLabel: string;
}
export interface Status {
  id: number;
  code: string;
  value: string;
  submittedAndPendingApproval: boolean;
  approved: boolean;
  rejected: boolean;
  withdrawnByApplicant: boolean;
  active: boolean;
  closed: boolean;
  prematureClosed: boolean;
  transferInProgress: boolean;
  transferOnHold: boolean;
  matured: boolean;
}
export interface SubStatus {
  id: number;
  code: string;
  value: string;
  none: boolean;
  inactive: boolean;
  dormant: boolean;
  escheat: boolean;
  block: boolean;
  blockCredit: boolean;
  blockDebit: boolean;
}
export interface Timeline {
  submittedOnDate: number[];
  submittedByUsername: string;
  submittedByFirstname: string;
  submittedByLastname: string;
  approvedOnDate: number[];
  approvedByUsername: string;
  approvedByFirstname: string;
  approvedByLastname: string;
  activatedOnDate: number[];
}
export interface ITransactionClient {
  id: number;
  accountNo: string;
  status: LegalForm;
  subStatus: ClientClassification;
  active: boolean;
  activationDate: number[];
  firstname: string;
  lastname: string;
  displayName: string;
  mobileNo: string;
  dateOfBirth: number[];
  gender: ClientClassification;
  clientType: ClientClassification;
  clientClassification: ClientClassification;
  isStaff: boolean;
  officeId: number;
  officeName: string;
  imageId: number;
  imagePresent: boolean;
  timeline: Timeline;
  legalForm: LegalForm;
  clientNonPersonDetails: ClientNonPersonDetails;
}
export interface ClientClassification {
  active: boolean;
  mandatory: boolean;
}
export interface ClientNonPersonDetails {
  constitution: ClientClassification;
  mainBusinessLine: ClientClassification;
}
export interface LegalForm {
  id: number;
  code: string;
  value: string;
}
export interface Timeline {
  submittedOnDate: number[];
  submittedByUsername: string;
  submittedByFirstname: string;
  submittedByLastname: string;
  activatedOnDate: number[];
  activatedByUsername: string;
  activatedByFirstname: string;
  activatedByLastname: string;
}
export interface INewTransaction {
  member: ITransactionClient | null;
  transaction: ITillTransaction | null;
  savingsAccount?: IClientSavingsAccount;
  loanAccount?: IClientLoanAccount;
  txnType: "loan_repayment" | "withdrawal" | "deposit";
}
export interface IAccountTransactionTemplate {
  type: Type;
  date: number[];
  currency: Currency;
  amount: number;
  principalPortion: number;
  interestPortion: number;
  feeChargesPortion: number;
  penaltyChargesPortion: number;
  manuallyReversed: boolean;
  paymentTypeOptions: PaymentTypeOption[];
}
export interface Currency {
  code: string;
  name: string;
  decimalPlaces: number;
  inMultiplesOf: number;
  displaySymbol: string;
  nameCode: string;
  displayLabel: string;
}
export interface PaymentTypeOption {
  id: number;
  name: string;
  description: string;
  isCashPayment: boolean;
  position: number;
}
export interface Type {
  id: number;
  code: string;
  value: string;
  disbursement: boolean;
  repaymentAtDisbursement: boolean;
  repayment: boolean;
  contra: boolean;
  waiveInterest: boolean;
  waiveCharges: boolean;
  accrual: boolean;
  writeOff: boolean;
  recoveryRepayment: boolean;
  initiateTransfer: boolean;
  approveTransfer: boolean;
  withdrawTransfer: boolean;
  rejectTransfer: boolean;
  chargePayment: boolean;
  refund: boolean;
  refundForActiveLoans: boolean;
}
export interface TillSlice {
  tills: any[];
  tellers: ITeller[];
  newTransaction?: INewTransaction;
  selectedClientAccounts: {
    isLoadingLoanAccounts: boolean;
    loanAccounts: IClientLoanAccount[];
    isLoadingSavingsAccounts: boolean;
    savingsAccounts: IClientSavingsAccount[];
  };
  selectedAccountTemplate?: IAccountTransactionTemplate;
  clientThumbnail: string;
}
const initialState: TillSlice = {
  tills: [],
  tellers: [],
  selectedClientAccounts: {
    isLoadingLoanAccounts: false,
    loanAccounts: [],
    isLoadingSavingsAccounts: false,
    savingsAccounts: [],
  },
  clientThumbnail: "",
};

const tillSlice = createSlice({
  name: "till",
  initialState,
  reducers: {
    setTellers: (state, action: PayloadAction<any[]>) => {
      state.tellers = action.payload;
    },
    setTills: (state, action: PayloadAction<any[]>) => {
      state.tills = action.payload;
    },
    setTransansationMember: (state, action: PayloadAction<ITransactionClient>) => {
      if (state.newTransaction == null) {
        state.newTransaction = {
          member: null,
          transaction: null,
          txnType: "loan_repayment",
        };
      }
      state.newTransaction.member = action.payload;
    },
    setTransansationLoanAccount: (state, action: PayloadAction<IClientLoanAccount>) => {
      if (state.newTransaction == null) return;
      state.newTransaction.savingsAccount = undefined;
      state.newTransaction.loanAccount = action.payload;
    },
    setTransansationSavingsAccount: (state, action: PayloadAction<IClientSavingsAccount>) => {
      if (state.newTransaction == null) return;
      state.newTransaction.loanAccount = undefined;
      state.newTransaction.savingsAccount = action.payload;
    },
    setTransansationTransaction: (state, action: PayloadAction<ITillTransaction>) => {
      if (state.newTransaction == null) return;
      state.newTransaction.transaction = action.payload;
    },
    setTransactionType: (state, action: PayloadAction<"loan_repayment" | "withdrawal" | "deposit">) => {
      if (state.newTransaction == null) return;
      state.newTransaction.txnType = action.payload;
    },
    setClientLoanAccounts: (state, action: PayloadAction<IClientLoanAccount[]>) => {
      if (state.newTransaction == null) return;
      state.selectedClientAccounts.loanAccounts = action.payload;
    },
    setClientSavingsAccounts: (state, action: PayloadAction<IClientSavingsAccount[]>) => {
      if (state.newTransaction == null) return;
      state.selectedClientAccounts.savingsAccounts = action.payload;
    },
    setIsLoadingLoanAccounts: (state, action: PayloadAction<boolean>) => {
      if (state.newTransaction == null) return;
      state.selectedClientAccounts.isLoadingLoanAccounts = action.payload;
    },
    setIsLoadingSavingsAccounts: (state, action: PayloadAction<boolean>) => {
      if (state.newTransaction == null) return;
      state.selectedClientAccounts.isLoadingSavingsAccounts = action.payload;
    },
    setClientThumbnail: (state, action: PayloadAction<string>) => {
      state.clientThumbnail = action.payload;
    },
    clearMemberSession: (state) => {
      state.clientThumbnail = "";
      state.newTransaction = undefined;
      state.selectedAccountTemplate = undefined;
      state.selectedClientAccounts = {
        isLoadingLoanAccounts: false,
        isLoadingSavingsAccounts: false,
        loanAccounts: [],
        savingsAccounts: [],
      };
    },
    setAccountTxnTemplate: (state, action: PayloadAction<IAccountTransactionTemplate>) => {
      state.selectedAccountTemplate = action.payload;
    },
  },
});

export const {
  setTills,
  setTellers,
  setTransansationMember,
  setTransansationTransaction,
  setTransansationLoanAccount,
  setTransansationSavingsAccount,
  setTransactionType,
  setClientLoanAccounts,
  setClientSavingsAccounts,
  setIsLoadingLoanAccounts,
  setIsLoadingSavingsAccounts,
  setClientThumbnail,
  clearMemberSession,
  setAccountTxnTemplate,
} = tillSlice.actions;

export const fetchTellers =
  (filter: any): AppThunk =>
  async (dispatch: Dispatch) => {
    if (filter.limit) {
    }
    const response = await tkbApi.teller.fetch();
    dispatch(setTellers(response.data));
  };

export const fetchClientLoanAccounts =
  (filter: any): AppThunk =>
  async (dispatch: Dispatch, getState) => {
    dispatch(setIsLoadingLoanAccounts(true));
    if (filter.limit) {
    }
    const clientId = getState().till.newTransaction?.member?.id;
    if (clientId == null) return;
    const response = await tkbApi.client.fetchLoanAccounts(clientId);
    dispatch(setClientLoanAccounts(response.data.loanAccounts));
    dispatch(setIsLoadingLoanAccounts(false));
  };

export const fetchClientSavingsAccounts =
  (filter: any): AppThunk =>
  async (dispatch: Dispatch, getState) => {
    dispatch(setIsLoadingSavingsAccounts(true));
    if (filter.limit) {
    }
    const clientId = getState().till.newTransaction?.member?.id;
    if (clientId == null) return;
    const response = await tkbApi.client.fetchSavingsAccounts(clientId);
    dispatch(setClientSavingsAccounts(response.data.savingsAccounts));
    dispatch(setIsLoadingSavingsAccounts(false));
  };

export const fetchTransactionTemplate = (): AppThunk => async (dispatch: Dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(setIsLoadingSavingsAccounts(true));
      const isLoan = getState().till.newTransaction?.loanAccount != null;
      if (isLoan) {
        const loanId = getState().till.newTransaction?.loanAccount?.id;
        if (loanId == null) return;
        const response = await tkbApi.teller.fetchLoanTxnTemplate(loanId);
        dispatch(setAccountTxnTemplate(response.data));
      } else {
        const accountId = getState().till.newTransaction?.savingsAccount?.id;
        if (accountId == null) return;
        const response = await tkbApi.teller.fetchLoanTxnTemplate(accountId);
        dispatch(setAccountTxnTemplate(response.data));
      }
      dispatch(setIsLoadingSavingsAccounts(false));
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export default tillSlice.reducer;
